import {
  Box,
  Grid,
  FormControl,
  Typography,
  MenuItem,
  Theme,
  Avatar,
} from "@mui/material";
import { Button } from "@mui/material";
import { paymentStatusArr, subscriptionStatusArr } from "../../utils/appData";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { makeStyles, createStyles } from "@mui/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import DateTimePicker from "../../components/formsUi/dateTimePicker";
import CustomSelect from "../../components/formsUi/select/customSelect";
import Utils from "../../utils";
import Schema from "../../schema";
import { getSubscriptionHistory } from "./action";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterActiveButton: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "20px",
    },
    datePicker: {
      marginTop: "5px",
    },
    insuranceCompany: {
      marginTop: "29px",
    },
    status: {
      marginTop: "29px",
    },
    customContentStyle: {
      width: "100%",
      maxWidth: "none",
    },
    typography: {
      fontSize: "1.2rem",
      "@media (min-width:600px)": {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.4rem",
      },
    },
    flexBox: {
      display: "flex",
      "& .MuiAvatar-root": {
        height: "25px",
        width: "20px",
      },
    },
    marginFlag: {
      marginLeft: "10px",
      textTransform: "uppercase",
      fontSize: "14px",
    },
  })
);

function FilterComponent({ data }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = {
    from_date: "",
    to_date: "",
    payment_status: "",
    subscription_status: "",
  };
  const handleClear = () => {
    dispatch({
      type: Utils.ActionName.SUBSCRIPTION_HISTORY,
      payload: {
        from_date: "",
        to_date: "",
        payment_status: "",
        subscription_status: "",
      },
    });
    dispatch(getSubscriptionHistory());
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema.subscriptionFilterSchema}
        onSubmit={(values) => {
          dispatch({
            type: Utils.ActionName.SUBSCRIPTION_HISTORY,
            payload: {
              from_date: values.from_date,
              to_date: values.to_date,
              payment_status: values.payment_status,
              subscription_status: values.subscription_status,
            },
          });
          dispatch(getSubscriptionHistory());
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Box className={classes.filterActiveButton}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={2}>
                  <Box className={classes.status}>
                    <FormControl fullWidth>
                      <CustomSelect
                        name="payment_status"
                        label="Select Invoice Status"
                        size="small"
                        value={values.payment_status}
                        options={paymentStatusArr.map(
                          (option: any, index: any) => {
                            return (
                              <MenuItem key={index} value={option.id}>
                                <div className={classes.flexBox}>
                                  <div className={classes.marginFlag}>
                                    {option.name}
                                  </div>
                                </div>
                              </MenuItem>
                            );
                          }
                        )}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={2}>
                  <Box className={classes.status}>
                    <FormControl fullWidth>
                      <CustomSelect
                        name="subscription_status"
                        label="Select Subscription Status"
                        size="small"
                        value={values.subscription_status}
                        options={subscriptionStatusArr.map(
                          (option: any, index: any) => {
                            return (
                              <MenuItem key={index} value={option.id}>
                                <div className={classes.flexBox}>
                                  <div className={classes.marginFlag}>
                                    {option.name}
                                  </div>
                                </div>
                              </MenuItem>
                            );
                          }
                        )}
                      />
                    </FormControl>
                  </Box>
                </Grid> */}
                <Grid item xs={12} sm={12} md={4}>
                  <Typography sx={{ marginBottom: "5px" }}>Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <DateTimePicker
                          name="from_date"
                          size="small"
                          label="From"
                          // value={}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <DateTimePicker
                          name="to_date"
                          size="small"
                          label="To"
                          // value={}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={5} sm={4} md={2.8}>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      // onClick={handleClick}
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        minWidth: "auto",
                        marginTop: "26px",
                        marginRight: "16px",
                      }}
                    >
                      Apply
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="reset"
                      size="large"
                      sx={{
                        fontWeight: "bold",
                        minWidth: "auto",
                        marginTop: "26px",
                      }}
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FilterComponent;
