import { Typography, Theme, Grid, Box, Avatar, MenuItem } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import CustomFileUpload from "../../components/formsUi/inputFile/customFileUpload";
import FormField, { fontSizeData } from "../../schema/formField";
import ImgCrop from "../../components/imgCrop";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  checkImageWAH,
  convertBannerWebsiteManagementPayload,
  renderFile,
  uploadDocumentImage,
} from "../../utils/commonFunctions";
import Utils from "../../utils";
import { LocalImages } from "../../utils/images";
import ButtonWrapper from "../../components/formsUi/button";
import InputColor from "../../components/formsUi/textFiled/InputColor";
import TextArea from "../../components/formsUi/textFiled/textArea";
import SelectWrapper from "../../components/formsUi/select";
import { getWebsiteManagement, updateWebsiteManagement } from "./action";
import { ReducersModal } from "../../modal";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      "&.MuiTypography-root": {
        font: `normal ${theme.typography.fontWeightBold} ${theme.spacing(
          2
        )} Roboto, sans-serif`,
      },
    },
    avatarCover: {
      position: "relative",
      display: "inline-block",
    },
    avatar: {
      boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.2)",
      "&.MuiAvatar-root": {
        width: theme.spacing(22),
        height: "100%",
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
      },
    },
    closeIcon: {
      position: "absolute",
      top: -10,
      right: -10,
      cursor: "pointer",
    },
    textArea: {
      margin: theme.spacing(1, 0),
      maxWidth: "100%",
      minWidth: "100%",
      minHeight: "120px !important",
    },
  })
);

export type ImageType = "banner" | "logoImg";

function TemplateManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState(FormField.bannerManagement);
  const [openImgCrop, setOpenImgCrop] = useState(false);
  const [openImgCropSrc, setOpenImgCropSrc] = useState("");
  const [type, setType] = useState<ImageType>("banner");
  const { banner_data, logo } = useSelector(
    (state: ReducersModal) => state.websiteManagementReducer
  );
  const {
    banner_bg_image,
    bg_color,
    description,
    heading,
    heading_color,
    heading_fz,
    sub_heading_color,
    sub_heading_fz,
  } = banner_data;

  useEffect(() => {
    dispatch(getWebsiteManagement());
  }, [dispatch]);

  useEffect(() => {
    if (banner_data) {
      setInitialValue({
        ...initialValue,
        bgImg: banner_bg_image,
        bgHeading: heading,
        bgDiscription: description,
        bgColor: bg_color,
        bgHeadingColor: heading_color,
        bgSubheadingColor: sub_heading_color,
        headingFontSize: heading_fz,
        subheadingFontSize: sub_heading_fz,
        logoImg: logo,
      });
    }
  }, [banner_data]);

  const handleSubmit = (
    values: typeof FormField.bannerManagement,
    { setSubmitting }: FormikHelpers<typeof FormField.bannerManagement>
  ) => {
    const payload = convertBannerWebsiteManagementPayload(values);
    dispatch(updateWebsiteManagement(payload, setSubmitting));
  };

  const handleBgFile = (file: string, type: ImageType) => {
    setType(type);
    setOpenImgCropSrc(file);
    handleOpenImgCrop();
  };

  const handleOpenImgCrop = () => {
    setOpenImgCrop(true);
  };
  const handleCloseImgCrop = () => {
    setOpenImgCrop(false);
  };

  const addCropperProfilePicture = (files: any, imgType: ImageType) => {
    return new Promise((resolve, reject) => {
      if (!imgType) return;
      dispatch(globalLoaderTrue());
      uploadDocumentImage(files.split(",")[1])
        .then((res: any) => {
          if (res?.status === 200) {
            if (imgType === "banner")
              setInitialValue({ ...initialValue, bgImg: res?.data?.image });
            else if (imgType === "logoImg")
              setInitialValue({ ...initialValue, logoImg: res?.data?.image });
            resolve("upload profile picture");
            dispatch(globalLoaderFalse());
          }
        })
        .catch((err: any) => {
          Utils.showAlert(
            2,
            err?.response?.data?.messages && err?.response?.data?.messages[0]
          );
          reject(
            err?.response?.data?.messages && err?.response?.data?.messages[0]
          );
          dispatch(globalLoaderFalse());
        });
    });
  };
  
  
  return (
    <>
      <Formik
        initialValues={initialValue}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              <Box className="bg-white">
                <Box>
                  <Typography className={classes.heading}>
                    Banner Management
                  </Typography>
                  <Grid container spacing={1.5} sx={{ my: 0.5 }}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "baseline",
                          height: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: (theme) =>
                              theme.typography.fontWeightMedium,
                            fontSize: (theme) => theme.spacing(1.4),
                          }}
                        >
                          Banner Background Image
                        </Typography>
                        <Box sx={{ my: 1 }}>
                          {values.bgImg ? (
                            <div className={classes.avatarCover}>
                              <Avatar
                                variant="square"
                                className={classes.avatar}
                                alt="No Images"
                                src={renderFile(values.bgImg)}
                              />
                              <img
                                alt="Remy Sharp"
                                className={classes.closeIcon}
                                src={LocalImages.CloseIcon}
                                title="Remove photo"
                                onClick={() =>
                                  setInitialValue({
                                    ...values,
                                    bgImg: "",
                                  })
                                }
                              />
                            </div>
                          ) : (
                            <CustomFileUpload
                              name="bgImg"
                              handleFiles={(file: string) =>
                                handleBgFile(file, "banner")
                              }
                            />
                          )}
                        </Box>
                        <Box sx={{ display: "flex", align: "center" }}>
                          <InputColor name="bgColor" />
                          <Typography
                            sx={{
                              fontWeight: (theme) =>
                                theme.typography.fontWeightMedium,
                              fontSize: (theme) => theme.spacing(1.4),
                              ml: 1,
                            }}
                          >
                            Banner Background Colour
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "baseline",
                          height: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: (theme) =>
                              theme.typography.fontWeightMedium,
                            fontSize: (theme) => theme.spacing(1.4),
                          }}
                        >
                          Banner Background Heading
                        </Typography>
                        <TextArea
                          className={classes.textArea}
                          name="bgHeading"
                        />
                        <Box sx={{ display: "flex", align: "center" }}>
                          <InputColor name="bgHeadingColor" />
                          <Typography
                            sx={{
                              fontWeight: (theme) =>
                                theme.typography.fontWeightMedium,
                              fontSize: (theme) => theme.spacing(1.4),
                              ml: 1,
                            }}
                          >
                            Banner Heading Colour
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "baseline",
                          height: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: (theme) =>
                              theme.typography.fontWeightMedium,
                            fontSize: (theme) => theme.spacing(1.4),
                          }}
                        >
                          Banner Background Description
                        </Typography>
                        <TextArea
                          className={classes.textArea}
                          name="bgDiscription"
                        />
                        <Box sx={{ display: "flex", align: "center" }}>
                          <InputColor name="bgSubheadingColor" />
                          <Typography
                            sx={{
                              fontWeight: (theme) =>
                                theme.typography.fontWeightMedium,
                              fontSize: (theme) => theme.spacing(1.4),
                              ml: 1,
                            }}
                          >
                            Banner Sub Heading Colour
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1.5} sx={{ my: 0.5 }}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: (theme) =>
                              theme.typography.fontWeightMedium,
                            fontSize: (theme) => theme.spacing(1.4),
                          }}
                        >
                          Banner Heading Font Size
                        </Typography>
                        <SelectWrapper
                          name="headingFontSize"
                          options={fontSizeData.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.key}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                          size="small"
                          sx={{ mt: 0.5 }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: (theme) =>
                              theme.typography.fontWeightMedium,
                            fontSize: (theme) => theme.spacing(1.4),
                          }}
                        >
                          Banner Sub Heading Font Size
                        </Typography>
                        <SelectWrapper
                          name="subheadingFontSize"
                          options={fontSizeData.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.key}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                          size="small"
                          sx={{ mt: 0.5 }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="bg-white" sx={{ mt: 1.5 }}>
                <Box>
                  <Typography className={classes.heading}>
                    Logo Management
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    {values.logoImg ? (
                      <div className={classes.avatarCover}>
                        <Avatar
                          sx={{
                            width: (theme) => `${theme.spacing(10)} !important`,
                            height: (theme) =>
                              `${theme.spacing(10)} !important`,
                          }}
                          variant="square"
                          className={classes.avatar}
                          alt="No Images"
                          src={renderFile(values.logoImg)}
                        />
                        <img
                          alt="Remy Sharp"
                          className={classes.closeIcon}
                          src={LocalImages.CloseIcon}
                          title="Remove photo"
                          onClick={() =>
                            setInitialValue({
                              ...values,
                              logoImg: "",
                            })
                          }
                        />
                      </div>
                    ) : (
                      <CustomFileUpload
                        name="logoImg"
                        handleFiles={(file: string) =>
                          checkImageWAH(file).then(() => {
                            handleBgFile(file, "logoImg");
                          })
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 2, textAlign: "right" }}>
                <ButtonWrapper type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Loading..." : "Save"}
                </ButtonWrapper>
              </Box>
            </Form>
          );
        }}
      </Formik>
      <ImgCrop
        src={openImgCropSrc}
        open={openImgCrop}
        handleClose={handleCloseImgCrop}
        uploadImage={addCropperProfilePicture}
        type={type}
      />
    </>
  );
}

export default TemplateManagement;
