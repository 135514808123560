import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSentClaimDetails,orderMarkComplete } from "../action";
import moment from "moment";
import Utils from "../../../utils";
import {
  // calculateTotalAmountDetails,
  checkStatus, renderFile,
} from "../../../utils/commonFunctions";
import { Box } from "@mui/system";
import { Avatar, Button, Chip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { ReducersModal } from "../../../modal";
import MedicalInformation from "./medicalInformation";
import PersonIcon from "@mui/icons-material/Person";
import PreviewImg from "../../../components/previewImg";
import { LocalImages } from "../../../utils/images";


const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "#fff";
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 450,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 2,
  px: 4,
  pb: 3,
};

function SentClaimDetail() {
  const dispatch = useDispatch();
  let { claimId } = useParams();
  const [claim_id,setClaimId] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [isHide, setIsHide] = useState(0);

  const claimDetail = useSelector((state: ReducersModal) => state.sentClaimReducer);
  const {
    id,
    user_avatar,
    user_name,
    user_gender,
    user_date_birth,
    user_phone,
    insurance_name,
    insurance_avatar,
    doctor_name,
    doctor_phone,
    status,
    entity_id,
    beneficiary,
    hospital_name,
    medicine_information,
    documents,
    phone_list,
    total_amount_full,
    icd10_list,
    sub_benefit_name,
    benefit_name,
    show_complete_button,
    order_id
  } = claimDetail;


  const handleOpen = (e: any, id: any) => {
    setOrderId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);


  const handleCompleteOrder = () => {
    if (orderId) {
      const params = { order_id: orderId }
      setIsHide(1);
      dispatch(orderMarkComplete(params));
      dispatch(getSentClaimDetails(claim_id));
    }

    setOpen(!open);
  };


  useEffect(() => {
    if (claimId) {
      setClaimId(claimId)
      dispatch(getSentClaimDetails(claimId));
    }
  }, [claimId, dispatch]);

  const checkPhoneNumber = () => {
    if (beneficiary?.entity_id === entity_id) {
      return user_phone;
    } else {
      return beneficiary?.login_phone ? beneficiary?.login_phone : user_phone;
    }
  };

  const checkPhoneLabel = () => {
    if (beneficiary?.entity_id === entity_id) {
      return "Patient Phone Number";
    } else {
      return "Family Phone Number";
    }
  };

  const checkMemberNumberLabel = () => {
    let cardLabel = "Member number";
    if (beneficiary?.card_type === "2") {
      cardLabel = "Smart card number ";
    } else if (beneficiary?.card_type === "3") {
      cardLabel = "LCT member number";
    }
    return cardLabel;
  };

  const checkMemberNumber = () => {
    let beneficiaryName = "";
    if (beneficiary?.card_type === "1") {
      beneficiaryName = beneficiary?.entity_id;
    } else if (beneficiary?.card_type === "2") {
      beneficiaryName = beneficiary?.employee_number;
    } else if (beneficiary?.card_type === "3") {
      beneficiaryName = beneficiary?.employee_number;
    } else {
      beneficiaryName = beneficiary?.entity_id;
    }
    return beneficiaryName;
  };

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const previewImgOpen = () => {
    handleOpenPreview();
  };

  return (
    <div className="page-claim-detail">
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <div className="bg-white space-between">
            <h4>E-Claim: {id ? id : "N/A"}</h4>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {Number(status) === 1 && (
                <Button
                  size="small"
                  sx={[
                    {
                      color: "var(--white)",
                      mr: 0.5,
                    },
                  ]}
                  variant="contained"
                  color="primary"
                >
                  Edit
                </Button>
              )}
              <h4>Status: {checkStatus(Number(status))}</h4>
            </Box>
            {show_complete_button && !isHide ? <Button
              size="small"
              sx={[
                {
                  color: "var(--white)",
                  mr: 0.5,
                },
              ]}
              variant="contained"
              color="primary"
              onClick={(e) => handleOpen(e, order_id)}
            >
              Mark Order Complete
            </Button> : ''}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ flexGrow: 1, alignItems: "stretch" }}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="bg-white h-100">
                <h3 className="mb-10">Patient Information</h3>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Profile Picture</h4>
                    {user_avatar || beneficiary?.avatar ? (
                      <Avatar
                        src={
                          beneficiary?.entity_id === entity_id
                            ? renderFile(user_avatar)
                            : beneficiary?.avatar
                              ? renderFile(beneficiary?.avatar)
                              : ""
                        }
                        alt=""
                        sx={{
                          width: "110px",
                          height: "110px",
                          borderRadius: "10px",
                          backgroundColor: (theme) => theme.palette.grey[200],
                        }}
                      >
                        <PersonIcon
                          color="primary"
                          sx={{
                            fontSize: "100px",
                          }}
                        />
                      </Avatar>
                    ) : (
                      <Avatar
                        variant="square"
                        sx={{
                          width: "110px",
                          height: "110px",
                          backgroundColor: (theme) => theme.palette.grey[200],
                        }}
                      >
                        <PersonIcon
                          color="primary"
                          sx={{
                            fontSize: "100px",
                          }}
                        />
                      </Avatar>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12}>
                        <h4>Name of the Patient</h4>
                        <p>
                          {
                            beneficiary?.beneficiary_name
                              ? beneficiary?.beneficiary_name
                              : "N/A"}
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <h4>Gender</h4>
                        <p>
                          {beneficiary?.gender
                            ? beneficiary?.gender
                            : "Male"}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Date of Birth</h4>
                    <p>
                      {beneficiary?.entity_id === entity_id
                        ? moment(user_date_birth).format("MMMM Do YYYY")
                        : beneficiary?.date_of_birth
                          ? moment(beneficiary?.date_of_birth).format("MMMM Do YYYY")
                          : "N/A"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>{checkMemberNumberLabel()}</h4>
                    <p>{checkMemberNumber()}</p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>{checkPhoneLabel()}</h4>
                    <p>{checkPhoneNumber() ? `+${checkPhoneNumber()}` : "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Employer Name</h4>
                    <p>{beneficiary?.policy_holder_name ? beneficiary?.policy_holder_name : "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Benefit Name</h4>
                    <p>
                      {benefit_name ? benefit_name : "N/A"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Sub Benefit Name</h4>
                    <p>
                      {sub_benefit_name
                        ? sub_benefit_name
                        : "N/A"}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                  <div className="bg-white" style={{ marginTop: "-10px" }}>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <h3 className="mb-10">Insurance Company</h3>
                        <p>{insurance_name ? insurance_name : ""}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <img
                          src={insurance_avatar ? renderFile(insurance_avatar) : LocalImages.DefaultImage}
                          alt=""
                          style={{
                            width: "110px",
                            height: "110px",
                            borderRadius: "10px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    {icd10_list?.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <h3>ICD10 code(s):</h3>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            mt: 0.3,
                          }}
                        >
                          {icd10_list.map((icd) => {
                            return (
                              <Box key={icd.code} sx={{ my: 0.5 }}>
                                <Chip
                                  sx={{ color: "var(--white)", ml: 0.5 }}
                                  color="primary"
                                  label={`${icd?.code} (${icd?.code_description})`}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="bg-white">
                    <h3 className="mb-10">Clinic Information</h3>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <h4>Clinic Name</h4>
                        <p>{hospital_name ? hospital_name : "N/A"}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <h4>Clinic Phone Number</h4>
                        <p>{phone_list ? `+${phone_list}` : "N/A"}</p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="bg-white">
                    <h3 className="mb-10">Doctor Information</h3>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <h4>Prescribing Doctor's Name</h4>
                        <p>{doctor_name ? doctor_name : "N/A"}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <h4>Doctor Phone Number</h4>
                        <p>{doctor_phone ? `+${doctor_phone}` : "N/A"}</p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="bg-white">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 className="mb-10">Medical Information</h3>
              {medicine_information && (
                <h3 className="mb-10">
                  Total Amount: {total_amount_full ? total_amount_full : "N/A"}
                  {/* {calculateTotalAmountDetails(medicine_information)} KES{" "} */}
                </h3>
              )}
            </Box>
            <MedicalInformation sentClaim medicineList={medicine_information} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="bg-white">
            <h3 className="mb-10">Documents</h3>
            <Box>
              <Typography>Prescription Photo</Typography>
              <Box sx={{ display: "flex" }}>
                {documents.prescription?.length > 0 ? (
                  documents.prescription.map((item: any, i) => (
                    <Box key={i} sx={{ m: 0.4 }}>
                      <Avatar
                        onClick={() => {
                          setPreviewImgUrl(renderFile(item));
                          previewImgOpen();
                        }}
                        sx={{
                          width: (theme) => theme.spacing(10),
                          height: (theme) => theme.spacing(10),
                          cursor: "pointer",
                        }}
                        variant="square"
                        // className={classes.avatarDoc}
                        alt="Remy Sharp"
                        src={renderFile(item)}
                      />
                    </Box>
                  ))
                ) : (
                  <Typography>No Prescription photo available</Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography>Claim Photo</Typography>
              <Box sx={{ display: "flex" }}>
                {documents.claim?.length > 0 ? (
                  documents.claim.map((item: any, i) => (
                    <Box key={i} sx={{ m: 0.4 }}>
                      <Avatar
                        onClick={() => {
                          setPreviewImgUrl(renderFile(item));
                          previewImgOpen();
                        }}
                        sx={{
                          width: (theme) => theme.spacing(10),
                          height: (theme) => theme.spacing(10),
                          cursor: "pointer",
                        }}
                        variant="square"
                        // className={classes.avatarDoc}
                        alt="Remy Sharp"
                        src={renderFile(item)}
                      />
                    </Box>
                  ))
                ) : (
                  <Typography>No claim photo available</Typography>
                )}
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
      <PreviewImg open={openPreview} handleClose={handleClosePreview} image={previewImgUrl} />
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to complete the associated Order?
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 6, ml: 19 }}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 6, ml: 4, color: "#fff" }}
            onClick={() => handleCompleteOrder()}
          >
            Yes
          </Button>
        </Box>
      </StyledModal>
    </div>
  );
}

export default SentClaimDetail;
