import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Radio,
  Paper,
  Button,
  Typography,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { getSubscriptionHistory } from "./action";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import { ReducersModal, SubscriptionHistory } from "../../modal";
import Utils from "../../utils";
import FilterComponent from "./subscriptionFilter";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { renderFile } from "../../utils/commonFunctions";


function ManageSubscription() {
  const {
    // isApiLoadingOnAddPayment,
    isGetPMApiLoading,
    mpesa,
    isUpdatePaymentOptionApiLoading,
  } = useSelector((state: ReducersModal) => state.PaymentDetailsReducer);

  const {
    data: listData,
    count,
    limit,
    search,
    page,
  } = useSelector((state: ReducersModal) => state.SubscriptionHistoryReducer);

  const dispatch = useDispatch();

  const [count_needed, setCountNeeded] = useState(1);
  const [IsDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [filter, setFiletr] = useState(false);

  const handleFilterClick = () => {
    setFiletr(!filter);
  };

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.SUBSCRIPTION_HISTORY,
      payload: {
        type: 3,
      },
    });
    dispatch(getSubscriptionHistory());
  }, []);

  const handleChangeRowsPerPage = (event: any) => {
    dispatch({
      type: Utils.ActionName.SUBSCRIPTION_HISTORY,
      payload: {
        limit: +event.target.value,
        offset: 0,
      },
    });
    dispatch(getSubscriptionHistory());
  };

  useEffect(() => {
    console.log("dispatch rabban", "limit change " + limit);
    dispatch(getSubscriptionHistory());
  }, [limit]);

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.SUBSCRIPTION_HISTORY,
      payload: {
        offset: page * limit - limit,
      },
    });
    dispatch(getSubscriptionHistory());
  }, [page]);

  const handleChangePage = (value: any) => {
    dispatch({
      type: Utils.ActionName.SUBSCRIPTION_HISTORY,
      payload: {
        page: value + 1,
      },
    });
  };

  const handlePdfDownload = (id: any) => {
    setIsDownloadingPdf(true);
    let type = "invoice_pdf";
    Utils.constants.getAccessToken();
    let params = "";
    Utils.api.getApiCall(
      `${Utils.endPoints.subscription}?list_type=${type}${
        search ? `&search=${search}` : ""
      }${id ? `&invoice_id=${id}` : ""}${limit ? `&limit=${limit}` : ""}${
        count_needed ? `&is_count_needed=${count_needed}` : ""
      }`,
      params,
      (respData: any) => {
        const { data } = respData;
        setIsDownloadingPdf(false);
        window.open(
          renderFile(data.invoice_pdf_link)
        );
      },
      (error: any) => {
        let { data } = error;
      }
    );
  };

  const setSearch = (value: any) => {
    dispatch({
      type: Utils.ActionName.SUBSCRIPTION_HISTORY,
      payload: {
        search: value,
      },
    });
    dispatch(getSubscriptionHistory());
  };

  const toggleArrow = (filter: Boolean) => {
    return filter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  };

  return (
    <div className="page-my-profile">
      <div className="payment-detail-cover border">
        <div className="space-between payment-detail-header">
          <Typography variant="h6">Subscription Invoice Details</Typography>
        </div>

        <div className="account-info mt-20">
          <div className="table-cover mt-10 payment-detail-table">
            {isGetPMApiLoading ? (
              <CircularProgress />
            ) : (
              <>
                <div style={{ display: "flex", width: "100%" }}>
                  <div>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: 350,
                        marginBottom: "10px",
                        boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
                      }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search by amount/subscription name"
                        onChange={(e: any) => {
                          // handleSearchChange(e);
                          setSearch(e.target.value);
                        }}
                        value={search}
                      />
                      {search.length > 0 ? (
                        <Button>
                          <ClearIcon onClick={() => setSearch("")} />
                        </Button>
                      ) : (
                        ""
                      )}
                      <IconButton
                        type="submit"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={() => {}}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                  <div style={{ marginLeft: "70px", marginRight: "5px" }}>
                    <div className="dropdown-cover">
                      <Button
                        onClick={handleFilterClick}
                        variant="outlined"
                        size="large"
                        sx={{
                          maxWidth: "180px",
                          minWidth: "80px",
                          bgcolor: "var(--white)",
                        }}
                      >
                        <FilterListIcon />
                        &nbsp;
                        <span>Filters</span>&nbsp;
                        {toggleArrow(filter)}
                      </Button>
                    </div>
                  </div>
                </div>

                {filter ? <FilterComponent data={listData} /> : " "}

                <table>
                  <thead>
                    <tr>
                      <th style={{ fontSize: "16px" }}>Invoice ID</th>
                      <th style={{ fontSize: "16px" }} className="left">
                        Subscription Name
                      </th>
                      <th style={{ fontSize: "16px" }}>Duration</th>
                      <th style={{ fontSize: "16px" }}>Start Date</th>
                      <th style={{ fontSize: "16px" }}>Next Renewal Date</th>
                      <th style={{ fontSize: "16px" }}>Amount</th>
                      <th style={{ fontSize: "16px" }}>Invoice Status</th>
                      {/* <th style={{ fontSize: "16px" }}>Subscription Status</th> */}
                      <th style={{ fontSize: "16px" }} className="center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listData.length > 0 ? (
                      listData.map((pm, index) => (
                        <tr key={index + pm}>
                          <td style={{ width: "30px" }}>
                            <>{pm?.id}</>
                          </td>
                          <td className="center">
                            <>{pm?.subscription_name}</>
                          </td>
                          <td className="center">
                            <>
                              {pm?.duration}{" "}
                              {pm?.timeline?.charAt(0)?.toUpperCase() +
                                pm?.timeline?.slice(1)}
                            </>
                          </td>
                          <td className="center">
                            <>{moment(pm?.start_date).format("Do MMM YYYY")}</>
                          </td>
                          <td className="center">
                            <>{moment(pm?.end_date).format("Do MMM YYYY")}</>
                          </td>
                          <td className="center">
                            <>{pm?.price ? pm?.price + " KES" : ""}</>
                          </td>
                          <td className="center">
                            <>
                              {pm?.payment_status?.charAt(0)?.toUpperCase() +
                                pm?.payment_status?.slice(1)}
                            </>
                          </td>
                          {/* <td className="center">
                            <>
                              {pm?.status?.charAt(0)?.toUpperCase() +
                                pm?.status?.slice(1)}
                            </>
                          </td> */}
                          <td className="center">
                            <div
                              className="table-btn-action-cover"
                              style={{ justifyContent: "center" }}
                            >
                              <DownloadIcon
                                onClick={() => handlePdfDownload(pm.id)}
                              />
                            </div>
                          </td>{" "}
                        </tr>
                      ))
                    ) : (
                      <>
                        <tbody>
                          <div style={{ padding: "10px", textAlign: "center" }}>
                            No Data found
                          </div>
                        </tbody>
                      </>
                    )}
                  </tbody>
                </table>
              </>
              // ) : (
              //   <table>
              //     <div className="for-no-data">No Data found</div>
              //   </table>
              // )
            )}
            {listData.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={count}
                rowsPerPage={limit}
                page={page - 1}
                onPageChange={(e, value): any => handleChangePage(value)}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={<span>Rows:</span>}
                sx={{
                  ".MuiTablePagination-selectLabel, .MuiTablePagination-input":
                    {
                      fontWeight: "bold",
                      color: "primary",
                    },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageSubscription;
