import { Box, Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import { useRef } from "react";
import { LocalImages } from "../../utils/images";
import { ReducersModal } from "../../modal";
import { useSelector } from "react-redux";
import Utils from "../../utils";
import { renderFile } from "../../utils/commonFunctions";

function PrintQrCode() {
  const componentRef = useRef(null);

  const { avatar, pharmacy_name, branch_url } = useSelector(
    (state: ReducersModal) => state.MyProfilePersonalInfoReducer
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="bg-white">
      <Box
        sx={{
          textAlign: "center",
          height: "600px",
          width: "360px",
          backgroundColor: "#59f0a4",
          margin: "auto",
          padding: "15px",
        }}
        ref={componentRef}
      >
        <Box
          sx={{
            height: "60px",
            width: "60px",
            margin: "auto",
            backgroundColor: "white",
            borderRadius: "50%",
            border: "2px solid #59f0a4",
            overflow: "hidden",
            zIndex: "1",
            position: "relative",
          }}
        >
          <img
            src={avatar ? renderFile(avatar) : LocalImages.Logo}
            alt="headlogo"
            height="60px"
            width="60px"
          />
        </Box>
        <Box
          sx={{
            height: "510px",
            width: "330px",
            backgroundColor: "white",
            marginTop: "-30px",
            borderRadius: "7px",
          }}
        >
          <Box
            sx={{
              paddingTop: "30px",
              borderBottom: "3px solid #59f0a4",
            }}
          >
            <h3 style={{ marginTop: "5px" }}>{pharmacy_name || "N/A"}</h3>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ fontSize: "16px" }}>CONNECT WITH US ONLINE</p>
              <Box sx={{ marginTop: "11px" }}>
                <img
                  src={LocalImages.OnlineLogo}
                  alt="online logo"
                  height="25px"
                />
              </Box>
            </Box>
          </Box>
          <div style={{ borderBottom: "1.5px solid #59f0a4", height: "270px" }}>
            {branch_url && (
              <QRCode
                value={`https://${branch_url}`}
                style={{
                  display: "block",
                  margin: "20px auto",
                  width: "200px",
                  height: "200px",
                }}
              />
            )}
            <p
              style={{
                fontSize: "28px",
                fontWeight: "bolder",
                marginTop: "0px",
              }}
            >
              SCAN QR CODE
            </p>
          </div>
          <div style={{ padding: "10px" }}>
            <div>
              <ul
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <li>Doctor Consultation</li>
                <li>Order Medicines</li>
              </ul>
            </div>
            <div style={{ marginTop: "30px" }}>
              <ul
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <li>Lab Tests</li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    padding: "2px 15px",
                  }}
                >
                  <p style={{ marginTop: "0px", marginLeft: "10px" }}>
                    Powered by Livia
                  </p>
                  <div style={{ marginTop: "-5px" }}>
                    <img
                      src={LocalImages.Favicon}
                      alt="livia image"
                      height="25px"
                    />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button sx={{ mt: 1.5 }} variant="contained" onClick={handlePrint}>
          Print this out!
        </Button>
      </div>
    </div>
  );
}

export default PrintQrCode;
