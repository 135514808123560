import axios from "axios";
import { getAccessTokenFromLS, getCountryCodeFromLS, renderFile } from "../../utils/commonFunctions";
import Utils from "../../utils";

export default class InsuranceCompanyService {
    authHeaders = () => ({
		'LiviaApp-Token': getAccessTokenFromLS() || "",
		'LiviaApp-country': getCountryCodeFromLS() || "",
		'LiviaApp-language': 'en',
		'LiviaApp-city': '186301',
		// 'LiviaApp-Url': window.location.origin
        // 'LiviaApp-Url': getAppUrlInLS(),
	});

	verifyInsurance = (data: any, callback: Function, errCallBack: any) => {
		axios
			.patch(renderFile('/api/insurance-company/1'), data, {
				headers: this.authHeaders(),
			})
			.then((res) => {
				callback(res);
			})
			.catch((err) => errCallBack(err));
	};
	verifyOtp = (data: any, callback: Function, callbackError: any) => {
		axios
			.put(renderFile('/api/insurance-company/1'), data, {
				headers: this.authHeaders(),
			})
			.then((res) => {
				callback(res);
			})
			.catch((err) => {
				callbackError(err);
			});
	};
}
