import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReducersModal } from "../../../modal";
import { confirmPrescription } from "../action";
import {
  claimCreateErrMsg,
  getAddress,
  getLocation,
  validateRadiusRange,
} from "../../../utils/commonFunctions";
import Utils from "../../../utils";
import { providerNotInRangeNotification } from "../../myClaims/action";
import LocationPopupModal from "../../../components/modal/LocationPopupModal";
import { globalLoaderTrue } from "../../../reducer/rootReducer";

function Confirm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation().state;
  const { drafted_claim_id } = useSelector(
    (state: ReducersModal) => state.createDoctorClaimSecondStepReducer
  );
  const { id } = useSelector(
    (state: ReducersModal) => state.claimDetailReducer
  );
  const { data: ProviderDetails } = useSelector(
    (state: ReducersModal) => state.ProviderDetailsReducer
  );

  const { subscriptionDetails } = useSelector(
    (state: ReducersModal) => state.SubscriptionStatusReducer
  );

  console.log(drafted_claim_id, "drafted_claim_id", "id", id, location);
  const draftClaimId = location.editDoctor ? id : drafted_claim_id;
  const uploadPrescriptionAndClaim = (type: number) => {
    if (subscriptionDetails.isSubscriptionActive != 1) {
      navigate(Utils.routes.manageSubscription);
    } else {
      dispatch(globalLoaderTrue());
      getLocation(ProviderDetails?.is_geoTag_skipped)
        .then((res: any) => {
          if (res) {
            ProviderDetails.currentLat = res.coords.latitude;
            ProviderDetails.currentLng = res.coords.longitude;
            ProviderDetails.locationAccuracy = res.coords.accuracy;
            validateRadiusRange(
              ProviderDetails.currentLat,
              ProviderDetails.currentLng,
              ProviderDetails.provider_lat,
              ProviderDetails.provider_lng,
              ProviderDetails.provider_radius_range,
              ProviderDetails.locationAccuracy,
              ProviderDetails.is_geoTag_skipped
            )
              .then(() => {
                if (draftClaimId) {
                  let params;
                  if (type === 2) {
                    params = {
                      claim_id: draftClaimId,
                      submit_type: type,
                      // prescription_arr: [],
                      // claim_arr: [],
                      type_id: 2,
                    };
                  }
                  if (type === 1) {
                    params = {
                      claim_id: draftClaimId,
                      submit_type: type,
                      // prescription_arr: prescriptionArr,
                      // claim_arr: claimArr,
                      type_id: null,
                    };
                  }
                  dispatch(confirmPrescription(params, navigate, type));
                }
              })
              .catch(() => {
                getAddress(res.coords.latitude, res.coords.longitude).then(
                  (address: any) => {
                    const params = {
                      lat: res.coords.latitude,
                      lng: res.coords.longitude,
                      screen: "Claim",
                      action: "Create",
                      address: address,
                      portal: "Chemist",
                    };
                    dispatch(providerNotInRangeNotification(params));
                  }
                );
                dispatch({
                  type: Utils.ActionName.GET_PROVIDER_DETAILS,
                  payload: {
                    data: {
                      ...ProviderDetails,
                      geoTagErrorPopup: true,
                      isLocationEnabled: true,
                    },
                  },
                });
                return false;
              });
          }
        })
        .catch((err: any) => {});
    }
  };

  const geoTagErrorPopupManage = () => {
    dispatch({
      type: Utils.ActionName.GET_PROVIDER_DETAILS,
      payload: { data: { ...ProviderDetails, geoTagErrorPopup: false } },
    });
  };

  return (
    <div className="page-upload-prescription">
      <div className="bg-white mb-20">
        <Box>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            Template Claim ID: {draftClaimId ? draftClaimId : "N/A"}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{ fontWeight: "bold", fontSize: (theme) => theme.spacing(3.5) }}
          >
            This is a Doctor generated E-Claim so you can send it directly to
            insurance company without uploading any documents.
          </Typography>
        </Box>
      </div>
      <div className="bg-white mb-20">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography>Send This claim to insurance?</Typography>
          <Button
            onClick={() => uploadPrescriptionAndClaim(1)}
            variant="outlined"
            sx={{ mx: (theme) => theme.spacing(2) }}
          >
            Later
          </Button>
          <Button
            sx={[
              {
                color: "var(--white)",
              },
            ]}
            variant="contained"
            color="primary"
            onClick={() => uploadPrescriptionAndClaim(2)}
          >
            Now
          </Button>
        </Box>
      </div>
      <LocationPopupModal
        open={ProviderDetails.geoTagErrorPopup}
        content={ProviderDetails}
        handleClose={geoTagErrorPopupManage}
      />
    </div>
  );
}

export default Confirm;
